<template>
    <CompModal class="partition-member" ref="comp_modal" title="添加成员" isFull>
        <template #head-operate>
            <div class="modal-operate-btn" @click="onSubmit">提交</div>
        </template>
        <div class="row-box">
            <div class="table-box">
                <div class="search-box" v-if="searchFields && searchFields.length > 0">
                    <div v-for="item in searchFields" :key="item.fieldId" style="margin:5px 10px 5px 0">
                        <Select v-if="item.fieldType === 'radio' || item.fieldType === 'select' || item.fieldType === 'checkbox'" v-model.trim="searchData[item.fieldId]" :placeholder="'请选择' + item.fieldName" :multiple="item.fieldType === 'checkbox'" class="module" transfer>
                            <Option v-for="opt in item.options" :key="opt.value" :value="opt.value">{{ opt.label }}</Option>
                        </Select>

                        <Select v-else-if="item.fieldType === 'switch'" v-model.trim="searchData[item.fieldId]" :placeholder="'请选择' + item.fieldName" class="module" transfer>
                            <Option value="1">是</Option>
                            <Option value="2">否</Option>
                        </Select>

                        <DatePicker v-else-if="item.fieldType === 'datePicker'" v-model.trim="searchData[item.fieldId]" class="module" type="date" placeholder="请选择日期" transfer />

                        <Input v-else v-model.trim="searchData[item.fieldId]" :placeholder="'请输入' + item.fieldName"></Input>
                    </div>
                    <Button class="btn" type="primary" @click="onSearchPersonnel">搜索</Button>
                    <Button class="btn" type="success" @click="onResetPersonnel">
                        重置
                    </Button>
                </div>
                <ElemTable class="table" ref="table" isSelector :columns="columns" :tableApi="tableApi" data="[]" method="post" :processData="onProcessTableData"></ElemTable>
            </div>
            <div class="control-box">
                <Button type="primary" class="btn" @click="onProcessAdd">添加</Button>
                <Button type="warning" class="btn" @click="onProcessRemove">删除</Button>
                <Button type="error" class="btn" @click="onProcessClear">清空</Button>
            </div>
            <div class="selected-box">
                <div class="title">已选 ( {{ selected.length }} )</div>
                <div class="empty-box">暂无已选</div>
                <div class="item-box" v-for="(item, idx) in selected" :key="item.id" :class="{ ac: selectedEdit.includes(item.id) }" @click="onChangeSelectedEdit(item.id)">{{ idx + 1 }}. {{ item.name }} {{ item.phone }}</div>
            </div>
        </div>
    </CompModal>
</template>
<script>
import Request from "../../jointly/utils/request"
import CompModal from "../../residentdatabase/components/CompModal.vue"
import ElemTable from "../components/ElemTable"

export default {
    components: { CompModal, ElemTable },

    data() {
        return {
            columns: [],
            // 已选
            selected: [],
            // 已选框的编辑选择
            selectedEdit: [],
            // 表格接口
            tableApi: null,
            // 搜索字段
            searchFields: null,
            // 搜索数据
            searchData: {},
        }
    },

    methods: {
        display(appId, nodeId) {
            if (this.appId !== appId) {
                // 设置接口链接
                this.setAppId(appId)
                // 获取搜索字段
                this.getSearchFieldData(appId)
            }
            this.appId = appId
            // 当前节点 ID
            this.nodeId = nodeId
            // 获取已选
            this.getSelected()
            // 显示弹窗
            this.$refs.comp_modal.display()
        },

        async getSelected() {
            const members = await Request.post("/gateway/org/pc/grassrootsOrg/app/getHasDataUser", {
                rootOrgId: this.nodeId,
            })

            // 赋值已选
            this.selected = members.map(v => ({
                id: v.dataId,
                phone: v.mobile,
                name: v.username,
            }))
        },

        setAppId(appId) {
            this.tableApi = `/gateway/api/fastDev/AppData/findPageData?appId=${appId}&desensitize=false`
        },

        onProcessTableData(res) {
            const data = res.data

            const columns = []

            const columnNameList = data.columnNameList

            for (let i = 0; i < columnNameList.length; i++) {
                const c = columnNameList[i]

                columns.push({
                    title: c,
                    key: c,
                    align: "center",
                    render: (h, params) => {
                        const value = params.row[c]

                        // 判断值是否为图片链接
                        if (/http.*\.(jpg|jpeg|png)/.test(value)) {
                            var images

                            // 是否为数组
                            if (/^\[.*\]$/.test(value)) {
                                images = JSON.parse(value)
                            } else if (/\|/.test(value)) {
                                images = value.split("|")
                            } else if (/,/.test(value)) {
                                images = value.split(",")
                            } else {
                                images = [value]
                            }

                            const files = []

                            images?.forEach(item => {
                                if (!item) return
                                files.push(
                                    h("div", {
                                        style: {
                                            width: "50px",
                                            height: "50px",
                                            margin: "3px",
                                            background: `url(${item})`,
                                            "background-size": "cover",
                                            "background-position": "center",
                                            "border-radius": "3px",
                                            cursor: "pointer",
                                        },
                                        on: {
                                            click: () => {
                                                this.$refs.preview.display(item, images)
                                            },
                                        },
                                    })
                                )
                            })

                            return h(
                                "div",
                                {
                                    style: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "flex-wrap": "wrap",
                                        margin: "5px 0",
                                    },
                                },
                                files
                            )
                        }

                        return h("p", value || "-")
                    },
                })
            }

            // 最后添加操作列
            // columns.push(this.getOperate())

            const tableData = []
            const dataList = data.dataList

            for (let i = 0; i < dataList.length; i++) {
                const d = dataList[i]

                const obj = {
                    id: d.formDataId || d.id,
                }

                for (let ci = 0, cs = d.columnDataList; ci < cs.length; ci++) {
                    let text = cs[ci]

                    // 没有值，跳过
                    if (!text) {
                        continue
                    }

                    // 去除数组中括号
                    if (/^\[.*\]$/.test(text)) {
                        text = text.replace(/[\[\]"]/g, "")
                    }

                    obj[columnNameList[ci]] = text
                }

                tableData.push(obj)
            }

            this.columns = columns
            data.dataList = tableData

            return data
        },

        /**
         * 添加
         */
        onProcessAdd() {
            const s = this.$refs.table.getSelecteds()

            if (!s?.length) {
                return this.$Message.warning("请选择需要添加的记录！")
            }

            const selected = this.selected

            // 过滤已选
            selected.push(
                ...s
                    .filter(f => selected.findIndex(sf => sf.id === f.id) == -1)
                    .map(v => ({
                        id: v.id,
                        name: v["姓名"] || v[Object.keys(v).find(k => ~k.indexOf("姓名"))],
                        phone: v["手机号码"],
                    }))
            )
        },

        /**
         * 获取搜索字段数据
         */
        getSearchFieldData(appId) {
            this.$get("/gateway/api/fastDev/AppForm/getSearchField", {
                appId: appId,
            }).then(res => {
                if (res.code == 200) {
                    this.searchFields = res.dataList
                    this.searchData = {}
                } else {
                    this.$Message.error(res.desc.slice(0, 50))
                }
            })
        },

        /**
         * 搜索
         */
        onSearchPersonnel() {
            const res = []
            const sd = this.searchData

            for (let i = 0, keys = Object.keys(sd); i < keys.length; i++) {
                let k = keys[i]
                let value = sd[k]

                // 判空
                if (!value) continue

                // 处理日期格式数据
                if (value instanceof Date) {
                    value = this.$core.formatDate(value, "yyyy-MM-dd")
                }

                res.push({
                    queryFieldId: k,
                    queryFieldValue: value,
                    searchMethod: "Contains",
                })
            }

            this.$refs.table.search(res)
        },

        /**
         * 重置搜索
         */
        onResetPersonnel() {
            this.searchData = {}
            this.$refs.table.reset()
        },

        /**
         * 删除
         */
        onProcessRemove() {
            const s = this.selectedEdit

            if (!s?.length) {
                return this.$Message.warning("请选择需要删除的记录！")
            }

            for (let i = 0; i < s.length; i++) {
                let id = s[i]
                let idx = this.selected.findIndex(v => v.id == id)

                if (idx > -1) {
                    this.selected.splice(idx, 1)
                }

                s.splice(i, 1)
                i--
            }
        },

        /**
         * 清空
         */
        onProcessClear() {
            this.selected = []
            this.selectedEdit = []
        },

        /**
         * 已选框的编辑选择变化事件
         */
        onChangeSelectedEdit(id) {
            const idx = this.selectedEdit.findIndex(v => v === id)
            // 已选则移除，未选则添加
            idx > -1 ? this.selectedEdit.splice(idx, 1) : this.selectedEdit.push(id)
        },

        /**
         * 提交
         */
        onSubmit() {
            Request.post(
                "/gateway/org/pc/grassrootsOrg/app/saveDataId",
                {
                    rootOrgId: this.nodeId,
                    userList: this.selected.map(v => ({
                        dataId: v.id,
                        username: v.name,
                        mobile: v.phone.replaceAll(/\s|\|/g, ","),
                    })),
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("提交成功")
                // 关闭弹窗
                this.$refs.comp_modal.close()
                // 清空表格勾选
                this.$refs.table.clearSelecteds()
                // 清空已选
                this.onProcessClear()

                this.$emit("on-change", {
                    tag: "PartitionMember",
                })
            })
        },
    },
}
</script>

<style lang="less">
.partition-member {
    .modal-operate-btn {
        cursor: pointer;
        padding: 4px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2faaf7;
        color: #fff;
        border-radius: 4px;
    }

    .row-box {
        width: 100%;
        height: 100%;
        display: flex;

        > .table-box {
            flex-grow: 1;
            height: 100%;
            padding: 10px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .search-box {
                padding-bottom: 5px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                flex-shrink: 0;

                .btn {
                    margin: 5px 10px 5px 0;
                }
            }

            .table {
                flex-grow: 1;
            }
        }

        .control-box {
            width: 80px;
            height: 100%;
            margin: 0 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            .btn {
                margin: 5px 0;
            }
        }

        .selected-box {
            position: relative;
            width: 280px;
            height: calc(100% - 20px);
            margin: 10px;
            border: 1px solid #e3e3e3;
            border-radius: 6px;
            flex-shrink: 0;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            overflow-y: auto;

            > .title {
                position: sticky;
                top: 0;
                height: 40px;
                background-color: #eee;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }

            > .item-box {
                cursor: pointer;
                margin: 7px;
                padding: 7px 10px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid #ededed;
                color: #333;
                font-size: 14px;
                word-break: break-all;

                &.ac {
                    background: #2faaf7;
                    color: #fff;
                }
            }

            .empty-box {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: none;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #999;

                &:last-child {
                    display: flex;
                }
            }
        }
    }
}
</style>
