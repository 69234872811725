<template>
    <CompModal ref="representative_modal" title="设置户代表" @on-close="onResetData" width="80%">
        <template #head-operate>
            <div class="modal-operate-btn" @click="onSubmit">保存已选</div>
        </template>
        <div class="representative-box">
            <div class="search-box">
                <Input class="input" v-model="search" placeholder="输入姓名、手机号查询" style="width:300px" @on-enter="getSearchRes(search)"></Input>
                <Button class="btn" type="primary" icon="ios-search" @click="getSearchRes(search)">搜索</Button>
                <Button class="btn" type="success" icon="ios-refresh" @click="getSearchRes()">
                    重置
                </Button>
            </div>
            <ElemTable ref="elem_table" :columns="columns" :dataList="tableData" height="calc(100% - 52px)">
                <template #selected="evt">
                    <Checkbox :value="ac_ids.includes(evt.row.id)" @on-change="onChangeSelected($event, evt.row.id)"></Checkbox>
                </template>
                <template #operate="evt">
                    <ElemLook v-if="evt.row.source === 1" :row="evt.row" :id="evt.row.id" source="ubase" @on-change="Object.assign(evt.row, $event.value)"></ElemLook>
                    <p v-else>-</p>
                </template>
            </ElemTable>
        </div>
    </CompModal>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import ElemLook from "@/views/residentdatabase/components/ElemLook.vue"
import ElemTable from "./ElemTable.vue"

import Axios from "axios"
import Utils from "../../jointly/utils/utils"
import UserInfo from "../entity/UserInfo"

export default {
    components: {
        CompModal,
        ElemLook,
        ElemTable,
    },

    data() {
        return {
            search: "",
            ac_ids: [],
            tableData: [],
            columns: [
                {
                    title: "选择",
                    width: 100,
                    slot: "selected",
                },
                {
                    title: "姓名",
                    key: "userName",
                },
                {
                    title: "手机号",
                    key: "mobile",
                },
                {
                    title: "来源",
                    key: "source",
                    render: (h, v) => {
                        return v.row.source === 1 ? "房号" : "组织成员"
                    },
                },
                {
                    title: "操作",
                    width: 150,
                    slot: "operate",
                    float: "right",
                },
            ],
            // 成员列表
            memberList: null,
        }
    },

    watch: {
        tableData() {
            this.ac_ids = []
        },
    },

    methods: {
        async display(item) {
            this.item = item
            this.houseNo = item.nodeCode
            // 获取成员列表
            if (!this.memberList) {
                await this.getMemberList()
            }
            // 请求数据
            this.getList()
            // 显示弹窗
            this.$refs.representative_modal.display()
        },

        onResetData() {
            this.search = ""
            this.ac_ids = []
            this.tableData = []
        },

        // 获取数据
        getList() {
            const search = []

            if (this.search) {
                search.push({
                    queryFieldId: /^[0-9]+$/.test(this.search) ? "mobile" : "userName",
                    queryFieldValue: this.search,
                    searchMethod: "Contains",
                })
            }

            Axios({
                method: "post",
                url:
                    "/syaa/api/syuser/pc/uaUserInfo/selectUserBasePage" +
                    Utils.jsonToParams({
                        page: 1,
                        pageSize: 999,
                        hasHouseNum: true,
                        houseNo: this.houseNo,
                        communityCode: UserInfo.getCommunityCode(),
                    }),
                data: search,
            })
                .then(res => {
                    const data = res.data

                    if (data.code == 200 && data.dataList) {
                        let tb = [].concat(this.memberList || [])

                        for (let i = 0, l = data.dataList; i < l.length; i++) {
                            let v = l[i]

                            tb.unshift({
                                id: v.ubaseId,
                                userName: v.userName,
                                mobile: v.mobile,
                                source: 1,
                            })
                        }

                        this.tableData = this.cacheTableData = tb
                    } else {
                        this.$Message.error({
                            content: data.desc || data.msg,
                            background: true,
                        })
                    }
                })
                .catch(() => {
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
        },

        getSearchRes(search) {
            if (!search) {
                this.search = ""
                return (this.tableData = this.cacheTableData)
            }
            // 过滤结果
            this.tableData = this.cacheTableData.filter(v => v.userName.indexOf(search) > -1 || v.mobile.indexOf(search) > -1)
        },

        getMemberList() {
            return this.$get("/gateway/org/pc/grassrootsAdmin/selectOrgAdminList", {
                orgCodeSelf: UserInfo.get("orgCode"),
                communityCode: UserInfo.getCommunityCode(),
            }).then(res => {
                if (res.code === "200") {
                    const list = []

                    for (let i = 0, l = res.dataList; i < l.length; i++) {
                        let v = l[i]

                        list.push({
                            id: v.adminId,
                            userName: v.userName,
                            mobile: v.mobile,
                            source: 2,
                        })
                    }

                    this.memberList = list
                    this.tableData = [].concat(list)
                }
            })
        },

        onChangeSelected(evt, id) {
            let idx = this.ac_ids.indexOf(id)

            if (evt && idx === -1) {
                this.ac_ids.push(id)
            } else if (!evt && idx > -1) {
                this.ac_ids.splice(idx, 1)
            }
        },

        async onSubmit() {
            if (!this.ac_ids || this.ac_ids.length === 0) {
                return this.$Message.error("请至少选择一位户代表")
            }

            const custGlobals = []

            for (let i = 0, l = this.ac_ids; i < l.length; i++) {
                let f = Utils.find(this.tableData, c => c.id === l[i])

                if (f.i === -1) {
                    return this.$Message.error("数据不存在")
                }

                let v = f.data

                if (v.source === 1) {
                    await this.onSubmitByUbaseId(v.id)
                } else {
                    custGlobals.push({
                        custGlobalId: v.id,
                        userName: v.userName,
                    })
                }

                if (i === l.length - 1) {
                    this.$set(this.item.newExtra, "extra", {
                        representName: v.userName,
                    })
                }
            }

            if (custGlobals.length > 0) {
                await this.onSubmitByCustGlobalId(custGlobals)
            }

            this.$Message.success("设置成功")
            // 关闭弹窗
            this.$refs.representative_modal.close()
            // 触发事件
            this.$emit("on-change", {
                tag: "Representative",
            })
        },

        onSubmitByCustGlobalId(custGlobals) {
            return new Promise((resolve, reject) => {
                Axios({
                    method: "post",
                    url:
                        "/syaa/api/syuser/pc/uaUserInfo/addHouseRepresent" +
                        Utils.jsonToParams({
                            page: 1,
                            pageSize: 999,
                            hasHouseNum: true,
                            houseCode: this.houseNo,
                            communityCode: UserInfo.getCommunityCode(),
                        }),
                    data: custGlobals,
                })
                    .then(res => {
                        const data = res.data

                        if (data.code == 200) {
                            resolve()
                        } else {
                            this.$Message.error(res.desc || "设置户代表失败")
                            reject()
                        }
                    })
                    .catch(reject)
            })
        },

        onSubmitByUbaseId(ubaseId) {
            return new Promise((resolve, reject) => {
                this.$post(
                    `/syaa/api/syuser/pc/uaUserInfo/updateUserById?ubaseId=${ubaseId}`,
                    {
                        data: {
                            jiaTingHuDaiBiao: "1",
                        },
                    },
                    { "Content-Type": "application/json" }
                )
                    .then(res => {
                        if (res.code == 200) {
                            return resolve()
                        }
                        this.$Message.error(res.desc)
                        reject()
                    })
                    .catch(reject)
            })
        },
    },
}
</script>

<style lang="less" scoped>
.modal-operate-btn {
    cursor: pointer;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2faaf7;
    color: #fff;
    border-radius: 4px;
}
.representative-box {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;

    .search-box {
        padding-bottom: 20px;
        display: flex;
        align-items: center;

        .btn {
            margin-left: 10px;
        }
    }

    .ivu-checkbox-wrapper {
        margin-right: 0;
    }
}
</style>
