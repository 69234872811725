<template>
    <div>
        <div class="elem" v-show="!loading">
            <div class="head-box">
                <div class="parents-box">
                    <div class="title">当前体系架构：</div>
                    <div class="item" v-for="(item, idx) in parentNames" :key="idx">{{ item }}</div>
                </div>
                <div class="explain-box">
                    <div class="title">说明：</div>
                    <block v-if="type === 'edit' && displayAddBtn">
                        <div class="item-box">已划分：{{ allotCount }}户（{{ peopleCount }}人）</div>
                    </block>
                    <block v-else style="display:flex">
                        <div class="item-box blue">已选中（{{ selected.length }}户）</div>
                        <div class="item-box white">未选中（{{ totalCount - selected.length }}户）</div>
                    </block>
                    <div class="item-box" v-if="type === 'edit' && displayAddBtn">有户代表：{{ representativeCount }}户</div>
                    <div class="item-box" v-if="type === 'edit' && displayAddBtn">无户代表：{{ allotCount - representativeCount }}户</div>
                    <div class="intro" v-if="type === 'edit' && displayAddBtn">点击编辑房号按钮可进行移除操作</div>
                    <div class="intro" v-else>点击色块可选中或取消选中{{ isHouse ? "单个" : "全部" }}房号</div>
                    <block v-if="type === 'edit' && displayAddBtn">
                        <Button type="success" size="small" @click="onDisplayAllHouse">添加房号</Button>
                        <Button type="info" size="small" style="margin-left:10px" @click="editHouses = !editHouses">{{ editHouses ? "完成" : "编辑房号" }}</Button>
                    </block>
                    <Button type="success" v-else-if="!displayAddBtn" size="small" @click="onDisplayManageHouse">返回管理房号</Button>
                </div>
                <div class="operate" v-if="type !== 'edit' || !displayAddBtn">
                    <div class="step-box">
                        <div class="item" v-for="(item, idx) in steps" :key="idx" @click="onSelectLevel(idx)">{{ item.nodeName }}</div>
                    </div>
                    <div class="check" v-if="edit && isHouse">
                        <Checkbox v-if="steps.length > 0" :value="isAllCheck()" @on-change="onChangeCheckAll">全选</Checkbox>
                    </div>
                </div>
            </div>
            <div class="layout-box" v-if="(type !== 'edit' || !displayAddBtn) && steps.length > 0">
                <div class="item-box" v-for="(item, idx) in steps[steps.length - 1].child" :key="idx" :class="{ selected: item.selected, pointer: item.leaf == 2 || item.child }" @click="onSelectAllChild(item, steps.length + 1)">
                    <div class="total">{{ item.leaf == 2 ? (item.newExtra ? item.newExtra.liveUser : 0) + "人" : item.child ? item.child.length : 0 + item.isLast ? "户" : "" }}</div>
                    <div class="room pointer" v-if="item.child && item.child.length > 0" @click.stop="onClickLevel(item)">{{ item.isLast ? "选择房号" : "选择下级" }}</div>
                    <div class="content margin">{{ item.nodeName }}</div>
                </div>
            </div>

            <div class="flat-box" v-if="type === 'edit' && displayAddBtn">
                <div class="item-box" v-for="(flat, idx) in flats" :key="idx">
                    <div class="operate">
                        <div class="step-box">
                            <div class="item" v-for="(item, idx) in flat.steps" :key="idx">{{ item.nodeName }}</div>
                        </div>
                    </div>
                    <div class="layout-box" v-if="flat.houses && flat.houses.length > 0">
                        <div class="item-box" v-for="(item, index) in flat.houses" :key="index" v-show="item.selected">
                            <div class="total">{{ (item.newExtra ? item.newExtra.liveUser : 0) + "人" }}</div>
                            <div class="room pointer" v-if="item.newExtra.extra && item.newExtra.extra.representName" @click.stop="onSetRepresentative(item)">{{ item.newExtra.extra.representName }}</div>
                            <div class="room pointer" v-else @click.stop="onSetRepresentative(item)" style="color:#e85555">{{ "设置户代表" }}</div>
                            <div class="content margin">{{ item.nodeName }}</div>
                            <div class="remove-btn liefeng-icon liefeng-icon-close" @click="onSelectAllChild(item)" v-if="editHouses"></div>
                        </div>
                    </div>
                    <div class="empty" v-if="isNotDisplayItem(flat.houses)">
                        <Icon type="ios-alert" size="16" />
                        <p style="margin-left:10px">暂无房号</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading-box" v-show="loading">
            <Spin fix></Spin>
        </div>

        <Representative ref="representative" @on-change="$emit('on-change')"></Representative>
    </div>
</template>

<script>
import Representative from "./Representative"

import UserInfo from "../entity/UserInfo"

export default {
    components: {
        Representative,
    },

    data() {
        return {
            steps: [],
            // 父节点名称
            parentNames: [],
            // 是否房号层级
            isHouse: false,
            // 总户数
            totalCount: 0,
            // 已选户数
            allotCount: 0,
            // 总人数
            peopleCount: 0,
            // 已选户数
            selected: [],
            // 取消选择
            unselect: [],
            // 显示加载中
            loading: true,
            // 类型，select 选择房号 edit 编辑房号
            type: "select",
            // 显示添加房号按钮
            displayAddBtn: true,
            // 户代表统计（有户代表数）
            representativeCount: 0,
            // 扁平布局
            flats: null,
            // 编辑房号
            editHouses: false,
        }
    },

    watch: {
        steps: {
            handler(v) {
                // 是否为房号层级
                this.isHouse = v[v.length - 1].isLast
            },
            deep: true,
        },
    },

    props: {
        edit: {
            type: Boolean,
            default: false,
        },
    },

    created() {
        this.levels = {}
    },

    methods: {
        setNodeInfo(nodeId, type, parentNames) {
            this.nodeId = nodeId
            this.type = type
            this.parentNames = parentNames
            this.loading = true
            // 请求
            this.getTreeData().finally(() => {
                this.loading = false
            })
        },

        /**
         * 重置
         */
        reset() {
            // 返回第一层级
            if (this.steps.length > 1) this.steps.splice(1, this.steps.length)
            // 取消全部已选
            for (let i = 0, ls = this.levels, ks = Object.keys(ls); i < ks.length; i++) {
                const k = ks[i],
                    v = ls[k]
                for (let idx = 0; idx < v.length; idx++) {
                    this.onSelectAllChild(v[i], k, false)
                }
            }
        },

        /**
         * 初始化
         */
        initia(deep) {
            this.representativeCount = 0
            this.allotCount = 0
            this.peopleCount = 0
            this.levels = {}
            this.selected = []
            this.unselect = []
            this.editHouses = false

            if (deep) {
                this.original_selected = []
            }
        },

        /**
         * 获取已选房号
         */
        getSelected() {
            var s, u

            if (this.type === "edit") {
                let { original_selected, selected } = this
                // 过滤
                s = this.selected.filter(x => !original_selected.includes(x))
                u = this.original_selected.filter(x => !selected.includes(x))
            } else {
                s = this.selected
            }

            return {
                selected: s || [],
                unselect: u || [],
            }
        },

        /**
         * 获取房号架构树
         */
        getTreeData() {
            // 初始化
            this.initia(true)

            return this.$get("/gateway/org/pc/grassrootsOrg/generateHouseView", {
                rootOrgId: this.nodeId,
                communityCode: UserInfo.getCommunityCode(),
            }).then(res => {
                if (res.code == 200) {
                    if (!res.dataList || res.dataList.length == 0) {
                        return this.$Message.error("当前社区数据为空")
                    }
                    // 取第一个社区
                    const data = res.dataList[0]
                    // 缓存数据
                    if (this.type === "edit") this.cacheDataList = JSON.parse(JSON.stringify(data))
                    // 处理层级数
                    this.processLevels(data)
                    // 处理树节点
                    this.totalCount = this.processTree(data.child)
                    // 编辑模式下移除未选节点
                    if (this.type === "edit") {
                        this.displayAddBtn = true
                        this.processUnselectedTree(data)
                        this.totalCount = this.allotCount
                        // 缓存已有的房号，用于判断增加或减少房号
                        this.original_selected = JSON.parse(JSON.stringify(this.selected))
                        return
                    }
                    // 写入第一层级
                    this.steps = [data]
                } else this.$Message.error(res.desc)
            })
        },

        /**
         * 处理层级
         */
        processLevels(item, level = 1) {
            var levels = this.levels[level]

            if (!levels) {
                levels = this.levels[level] = []
            }

            levels.push(item)

            for (let i = 0, ls = item.child; i < ls?.length; i++) {
                this.processLevels(ls[i], level + 1)
            }
        },

        /**
         * 处理未选节点
         */
        processUnselectedTree(data) {
            const eliminate = c => {
                for (let i = 0; i < c.length; i++) {
                    const item = c[i]

                    if (!item.selected) {
                        c.splice(i, 1)
                        i--
                        continue
                    }

                    if (item.child?.length > 0) {
                        eliminate(item.child)
                    }
                }
            }

            eliminate(data.child)

            const flat = (c, res, cs = []) => {
                cs.push(c)

                if (c.isLast) {
                    return res.push({
                        steps: cs,
                        houses: c.child,
                    })
                }

                for (let i = 0, l = c.child; i < l?.length; i++) {
                    flat(l[i], res, JSON.parse(JSON.stringify(cs)))
                }
            }

            const flats = []

            flat(data, flats)

            this.flats = flats
        },

        /**
         * 处理房号架构树节点
         */
        processTree(child, level = 2, total = 0) {
            // 排序
            child.sort((a, b) => {
                try {
                    if (/^[0-9]*$/.test(a.nodeName) && /^[0-9]*$/.test(b.nodeName)) {
                        return a.nodeName === b.nodeName ? 0 : Number(a.nodeName) > Number(b.nodeName) ? 1 : -1
                    }
                    return a.nodeName.localeCompare(b.nodeName, "zh-Hans-CN")
                } catch (e) {
                    return 0
                }
            })

            for (let i = 0; i < child.length; i++) {
                const item = child[i]

                if (item.leaf === "2") {
                    if (item.newExtra?.allot === 1) {
                        this.onSelectAllChild(item, level, true)
                    } else if (item.newExtra?.occupy !== 0) {
                        // 移除已被选的房号
                        child.splice(i, 1)
                        // 节点已清空，请求移除当前节点
                        if (child.length === 0) return false
                        // 下标减一
                        i--
                        continue
                    }

                    // 总数自增
                    total++
                    continue
                }

                // 下级为房号的节点标记为最后一级
                if (item.child?.[0].leaf === "2") {
                    item.isLast = true
                } else if (!item.child || item.child.length === 0) {
                    // 移除空节点
                    child.splice(i, 1)
                    // 节点已清空，请求移除当前节点
                    if (child.length === 0) return false
                    i--
                    continue
                }

                // 继续处理子节点
                let cb = this.processTree(item.child, level + 1, total)

                if (cb === false) {
                    // 移除空节点
                    child.splice(i, 1)
                    // 节点已清空，请求移除当前节点
                    if (child.length === 0) return false
                    i--
                    continue
                } else total = cb
            }

            return total
        },

        /**
         * 选择层级
         */
        onSelectLevel(idx) {
            // 剩余层级需要大于 1 层
            if (this.steps.length < 2) return
            // 把选择的层级往后去除
            this.steps.splice(idx + 1, this.steps.length)
        },

        /**
         * 点击层级
         */
        onClickLevel(item) {
            // 没有下级则不再进入
            if (!item.child || item.child.length == 0) return
            // 添加一层
            this.steps.push(item)
        },

        /**
         * 判断是否为全选
         */
        isAllCheck() {
            for (let i = 0, l = this.steps[this.steps.length - 1].child; i < l?.length; i++) {
                if (!l[i].selected) {
                    return false
                }
            }

            return true
        },

        /**
         * 全选/取消全选事件
         */
        onChangeCheckAll(evt) {
            // 遍历子节点
            this.traverseChild(this.steps[this.steps.length - 1].child, evt)
            // 遍历父节点
            this.traverseParent(evt, this.steps[this.steps.length - 1].nodeId, this.steps.length)
            // 刷新
            this.$forceUpdate()
        },

        /**
         * 选择当前和全选子节点
         */
        onSelectAllChild(item, level, selected = !item.selected) {
            // 修改当前
            item.selected = selected
            // 是否为房号
            if (item.leaf === "2") {
                if (item.newExtra?.liveUser > 0 && !selected ? this.peopleCount > item.newExtra?.liveUser : true) {
                    selected ? (this.peopleCount += item.newExtra.liveUser) : (this.peopleCount -= item.newExtra.liveUser)
                }
                // 计算户代表
                if (item.newExtra?.extra?.representName) {
                    this.representativeCount += selected ? 1 : -1
                }
                this.allotCount += selected ? 1 : -1
            }
            // 处理房号已选
            this.processSelected(item)
            // 遍历子节点
            this.traverseChild(item.child, item.selected)
            // 遍历父节点
            if (level > 1) this.traverseParent(item.selected, item.parentId, level - 1)
            // 刷新
            this.$forceUpdate()
        },

        /**
         * 单独处理房号已选
         */
        processSelected(item) {
            // 是否为房号层级
            if (item.leaf === "2") {
                // 数据标记为已选的房号
                if (item.newExtra?.allot === 1) {
                    let i = this.unselect.indexOf(item.nodeCode)
                    if (!item.selected && i > -1) {
                        this.unselect.splice(i, 1)
                    } else if (item.selected && i === -1) {
                        this.unselect.push(item.nodeCode)
                    }
                }
                if (item.selected) {
                    return this.selected.push(item.nodeCode)
                }
                // 查找
                let idx = this.selected.indexOf(item.nodeCode)
                // 存在即移除
                idx > -1 && this.selected.splice(idx, 1)
            }
        },

        /**
         * 遍历子节点
         */
        traverseChild(child, selected) {
            if (!child || child.length == 0) return

            for (let i = 0; i < child.length; i++) {
                let v = child[i]
                // 选择或取消选择
                v.selected = selected
                // 处理房号已选
                this.processSelected(v)
                // 继续遍历下级
                this.traverseChild(v.child, selected)
            }
        },

        /**
         * 遍历父节点
         */
        traverseParent(selected, parentId, level) {
            for (let i = 0, ls = this.levels[level]; i < ls?.length; i++) {
                let v = ls[i]

                if (v.nodeId === parentId) {
                    if (!selected) {
                        for (let idx = 0, list = v.child; idx < list.length; idx++) {
                            // 子节点存在已选，跳出不处理
                            if (list[idx].selected) return
                        }
                    }

                    v.selected = selected

                    if (v.parentId && level > 1) {
                        this.traverseParent(selected, v.parentId, level - 1)
                    }

                    break
                }
            }
        },

        /**
         * 设置户代表
         */
        onSetRepresentative(item) {
            this.$refs.representative.display(item)
        },

        /**
         * 显示所有房号
         */
        onDisplayAllHouse() {
            // 初始化
            this.initia()
            // 取第一个社区
            const data = JSON.parse(JSON.stringify(this.cacheDataList))
            // 处理层级数
            this.processLevels(data)
            // 处理树节点
            this.totalCount = this.processTree(data.child)
            // 写入第一层级
            this.steps = [data]
            // 隐藏添加房号按钮
            this.displayAddBtn = false
        },

        /**
         * 恢复房号管理页面
         */
        onDisplayManageHouse() {
            // 初始化
            this.initia()
            const data = JSON.parse(JSON.stringify(this.cacheDataList))
            // 处理层级数
            this.processLevels(data)
            // 处理树节点
            this.totalCount = this.processTree(data.child)
            // 编辑模式下移除未选节点
            this.displayAddBtn = true
            this.processUnselectedTree(data.child)
            this.totalCount = this.allotCount
        },

        isNotDisplayItem(list) {
            for (let i = 0; i < list?.length; i++) {
                if (list[i].selected) return false
            }
            return true
        },

        /**
         * 统计房号信息
         */
        countHousesInfo() {
            const flats = this.flats
            // 初始化
            this.allotCount = 0
            this.allotPeopleCount = 0

            for (let i = 0; i < flats?.length; i++) {
                let h = flats[i].houses

                for (let j = 0; j < h?.length; j++) {
                    let v = h[j]
                    if (v.selected) {
                        this.allotCount++
                        this.allotPeopleCount += v.newExtra?.liveUser || 0
                    }
                }
            }
        },
    },
}
</script>

<style lang="less" scoped>
.elem {
    .head-box {
        position: sticky;
        padding: 10px 0;
        top: 0;
        margin-bottom: 10px;
        background: #fff;
        z-index: 30;
        box-shadow: 0 5px 10px rgba(255, 255, 255, 0.8);

        .parents-box {
            padding-bottom: 3px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .item {
                position: relative;
                padding-right: 20px;

                &::after {
                    content: ">";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:last-child::after {
                    display: none;
                }
            }
        }

        .explain-box {
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &:last-child {
                padding-bottom: 0;
            }

            .item-box {
                position: relative;
                margin: 5px 10px;
                line-height: 20px;

                &.blue,
                &.white {
                    padding-left: 50px;

                    &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 40px;
                        content: "";
                        border-radius: 4px;
                        background: #fff;
                        border: 1px solid #e3e3e3;
                    }
                }

                &.blue::after {
                    background: #5ab2e9;
                    border-color: #5ab2e9;
                }
            }

            .intro {
                color: #999;
                font-size: 13px;
                margin: 5px 25px 5px 15px;
            }
        }
    }

    .operate {
        padding-bottom: 10px;
        display: flex;
        align-items: center;

        &:last-child {
            padding-bottom: 0;
        }

        .step-box {
            border-left: 3px solid #5ab2e9;
            padding-left: 10px;
            display: flex;
            align-items: center;

            .item {
                position: relative;
                padding-right: 20px;
                cursor: pointer;

                &::after {
                    content: ">";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 20px;
                    height: 1px;
                    background: #b2b2b2;
                }

                &:last-child {
                    cursor: initial;

                    &::after,
                    &::before {
                        display: none;
                    }
                }
            }
        }

        .check {
            margin-left: 20px;
        }
    }

    .layout-box {
        display: flex;
        flex-wrap: wrap;

        > .item-box {
            position: relative;
            margin: 0 12px 12px 0;
            padding: 10px;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
            display: flex;
            align-items: center;
            background: #fff;
            color: #333;

            .total {
                position: absolute;
                top: 0;
                left: 0;
                padding: 10px 0 0 10px;
                line-height: 1;
                font-size: 12px;
                color: green;
            }

            .content {
                width: 130px;
                min-height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.margin {
                    margin-top: 30px;
                }
            }

            .room {
                position: absolute;
                top: 5px;
                right: 5px;
                padding: 3px 10px;
                line-height: 1;
                font-size: 12px;
                background: #f8f8f8;
                border: 1px solid #ececec;
                border-radius: 4px;
                color: #4bccb4;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                box-sizing: border-box;
                white-space: nowrap;
            }

            &.selected {
                background: #5ab2e9;
                color: #fff;

                .total {
                    color: #f3f3f3;
                }
            }

            .remove-btn {
                position: absolute;
                top: -7px;
                right: -7px;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #ffb6b6;
                color: #fff;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                font-size: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .pointer {
            cursor: pointer;
        }
    }

    .empty {
        color: #999;
        padding: 10px 40px 20px 40px;
        display: flex;
        align-items: center;
    }
}

.loading-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>
