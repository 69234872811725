<template>
    <div class="flow-tree" :class="[{ first: level === 0 }, arrange]">
        <div class="node-box">
            <div class="info-box" v-for="(item, idx) in nodes" :key="idx">
                <div
                    class="content-box"
                    :class="{
                        top: level > 0,
                        bottom: (item.isUnfold !== false || edit) && (merge || (item[getKey('child')] && item[getKey('child')] instanceof Array && item[getKey('child')].length > 1)),
                        'alone-top': nodes.length === 1,
                        'alone-bottom': nodes.length === 1 || (item[getKey('child')] && item[getKey('child')] instanceof Array && item[getKey('child')].length > 1),
                    }"
                >
                    <div class="wire" v-if="level > 0" :class="[nodes.length > 1 ? { left: idx > 0, right: idx < nodes.length - 1 } : null, { first: idx === 0, last: idx === nodes.length - 1 }]"></div>
                    <div class="content-base" :style="[styles, { width: item.width || styles.width || '150px', height: item.height || styles.height || '100px', cursor: item.click ? 'pointer' : '' }, item.style]" @click="item.click && item.click(item)">
                        <!-- 标记文本 -->
                        <div v-if="item.sign" class="sign-box" :style="item.sign.style">
                            <span v-if="item.sign.title">{{ item.sign.title }}</span>
                            <slot v-else :item="item" :level="level" :nodes="nodes" :node="node" :sign="item.sign"></slot>
                        </div>
                        <!-- 编辑按钮 - 减少 -->
                        <div class="edit-decrease" v-if="edit && (item.edit !== undefined ? item.edit !== false && item.edit === 'decrease' : true) && level > 0 && nodes.length > 1" @click.stop="onDecreaseNode(item, idx)"></div>
                        <!-- 编辑按钮 - 增加 -->
                        <div class="edit-increase" v-if="edit && (item.edit !== undefined ? item.edit !== false && item.edit === 'increase' : true) && !isLastLevel(item)" @click.stop="onIncreaseNode(item)"></div>
                        <!-- 展开按钮 -->
                        <div class="unfold-btn" v-if="!edit && unfold && item.unfold !== false && !isLastLevel(item)" :class="{ unfold: item.isUnfold !== false }" @click.stop="onChangeUnfold(item)">
                            <Icon class="icon" type="ios-arrow-down" />
                            <Icon class="icon" type="ios-arrow-down" />
                        </div>
                        <!-- 内容 -->
                        <div class="content">
                            <div class="box" v-if="item.content" v-html="item.content"></div>
                            <slot v-else :item="item" :level="level" :nodes="nodes" :node="node" :parents="parents"></slot>
                        </div>
                        <slot name="extra" :item="item" :level="level" :nodes="nodes" :node="node"></slot>
                    </div>
                    <div class="wire-bottom" v-if="merge" :class="[nodes.length > 1 ? { left: idx > 0, right: idx < nodes.length - 1 } : null, { first: idx === 0, last: idx === nodes.length - 1 }]"></div>
                </div>
                <FlowTree v-show="item.unfold === false || item.isUnfold !== false || edit" v-if="!merge && item[getKey('child')]" v-bind="getChildAttrs(item)" @on-change="onChangeEvent">
                    <template #default="evt">
                        <slot v-bind="evt"></slot>
                        <slot name="extra" v-bind="evt"></slot>
                    </template>
                </FlowTree>
            </div>
        </div>
        <!-- 合并的子节点 -->
        <FlowTree v-if="merge" :node="merge" :parents="getParents(item)" :level="level + 1" :color="color" :arrange="arrange" :init="init" :edit="edit" :keys="keys" :styles="styles" :changeBefore="changeBefore" @on-change="onChangeEvent">
            <template #default="evt">
                <slot v-bind="evt"></slot>
                <slot name="extra" v-bind="evt"></slot>
            </template>
        </FlowTree>
    </div>
</template>

<script>
export default {
    name: "FlowTree",

    data() {
        return {
            nodes: null,
            merge: null,
        }
    },

    props: {
        // 层级，仅内部传递使用
        level: {
            type: Number,
            default: 0,
        },
        // 父节点，仅内部传递使用
        parents: {
            type: Array,
            default: () => [],
        },
        // 节点配置
        node: Object | Array,
        // 导线颜色
        color: {
            type: String,
            default: "#5b8cab",
        },
        // 排列方式，横向：across 竖向：vertical
        arrange: {
            type: String,
            default: "vertical",
        },
        // 是否可编辑
        edit: {
            type: Boolean,
            default: false,
        },
        // 是否可展开&关闭
        unfold: {
            type: Boolean,
            default: true,
        },
        // 层级默认配置，新增节点时根据层级从此获取默认配置，如果没有就新增空节点
        // 示例：{ 1:{}, 2:{} }
        init: {
            type: Object,
            default: () => ({}),
        },
        // 自定义 key
        keys: {
            type: Object,
            default: () => ({}),
        },
        // 自定义全局样式
        styles: {
            type: Object,
            default: () => ({}),
        },
        // 节点变化前置事件
        changeBefore: {
            type: Function,
            required: false,
        },
    },

    watch: {
        node: {
            handler(v) {
                if (!v) return
                var merge = null
                // 单对象转数据对象
                v = v instanceof Array ? v : [v]
                // 保存筛选节点
                let list = []

                for (let i = 0; i < v.length; i++) {
                    let val = v[i]
                    // 合并类型，单独处理
                    if (val.type === "merge") merge = val
                    else list.push(val)
                }

                if (merge) {
                    if (list.length == 0) list.push(merge)
                    else this.merge = merge
                } else this.merge = null

                this.nodes = list
            },
            immediate: true,
            deep: true,
        },

        color: {
            handler(v) {
                document.body.style.setProperty("--main-color", v || "#5b8cab")
            },
            immediate: true,
        },
    },

    methods: {
        /**
         * 减少节点
         */
        async onDecreaseNode(item, idx) {
            if (this.changeBefore) {
                let cb = this.changeBefore({
                    type: "DECREASE",
                    value: item,
                    level: this.level,
                })
                // 返回 Promise 等待完成
                if (cb instanceof Promise) cb = await cb
                // 返回 false 终止
                if (cb === false) return
            }
            this.node.splice(idx, 1)
        },

        /**
         * 增加节点
         */
        async onIncreaseNode(item) {
            let init = this.init[this.level + 1]
            // 没有初始节点配置，拷贝最后一个子节点数据
            if (!init) {
                let child = item[this.getKey("child")]
                if (child && child.length > 0) {
                    init = child[child.length - 1]
                }
            }
            // 如没有初始配置则新增空节点
            init = init ? JSON.parse(JSON.stringify(init)) : {}
            if (this.changeBefore) {
                let cb = this.changeBefore({
                    type: "INCREASE",
                    value: init,
                    parent: item,
                    level: this.level + 1,
                })
                // 返回 Promise 等待完成
                if (cb instanceof Promise) cb = await cb
                // 返回 false 终止
                if (cb === false) return
                // 返回值，添加节点
                if (cb) return item[this.getKey("child")].push(...(cb instanceof Array ? cb : [cb]))
            }
            // 增加
            item[this.getKey("child")].push(init)
            // 触发事件
            this.$emit("on-change", {
                tag: "FlowTree",
                type: "INCREASE_NODE",
                value: init,
                parent: item,
                level: this.level + 1,
            })
        },

        /**
         * 监听子节点变化事件，用于冒泡到顶层
         */
        onChangeEvent(evt) {
            this.$emit("on-change", evt)
        },

        /**
         * 展开或关闭
         */
        onChangeUnfold(item) {
            this.$set(item, "isUnfold", item.isUnfold === undefined ? false : !item.isUnfold)
        },

        /**
         * 获取自定义 key
         */
        getKey(key) {
            return this.keys[key] || key
        },

        /**
         * 是否为最后层级
         */
        isLastLevel(item) {
            return !(item[this.getKey("child")]?.length > 0)
        },

        /**
         * 获取父节点
         */
        getParents(item) {
            let res = []
            // 拷贝节点
            this.parents.forEach(v => {
                res.push(v)
            })
            // 添加当前节点
            res.push(item)
            return res
        },

        /**
         * 获取子节点属性
         */
        getChildAttrs(item) {
            return {
                ...this._props, // 当前节点所有属性
                node: item[this.getKey("child")],
                parents: this.getParents(item),
                level: this.level + 1,
            }
        },
    },
}
</script>

<style lang="less" scoped>
// 连接线圆弧
@line-radius: 15px;

:root {
    --main-color: #5b8cab;
}

.vertical_row(@a, @b) {
    content: "";
    position: absolute;
    @{a}: 0;
    @{b}: calc(50% - 1px);
    height: 20px;
    width: calc(50% + 2px);
    border-@{a}: 2px solid var(--main-color);
    border-@{b}: 2px solid var(--main-color);
}

.vertical_col(@a) {
    content: "";
    position: absolute;
    @{a}: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 20px;
    background: var(--main-color);
}

.across_row(@a) {
    content: "";
    position: absolute;
    @{a}: 0;
    top: calc(50% - 1px);
    height: 2px;
    width: 20px;
    background: var(--main-color);
}

.across_col(@a, @b) {
    content: "";
    position: absolute;
    @{a}: calc(50% - 1px);
    @{b}: 0;
    width: 20px;
    height: calc(50% + 2px);
    border-@{a}: 2px solid var(--main-color);
    border-@{b}: 2px solid var(--main-color);
}

.edit_btn(@a, @b, @t) {
    cursor: pointer;
    position: absolute;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    @{a}: calc(50% - 10px);
    @{b}: -10px;
    width: 20px;
    height: 20px;
    background: if(@t=increase, #2faaf7, #fff);
    border-radius: 50%;
    z-index: 50;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        left: 4px;
        right: 4px;
        height: 2px;
        border-radius: 1px;
        background: if(@t=increase, #fff, #666);
        display: if(@t=false, none, block);
    }

    &::before {
        content: "";
        position: absolute;
        left: calc(50% - 1px);
        top: 4px;
        bottom: 4px;
        width: 2px;
        border-radius: 1px;
        background: #fff;
        display: if(@t=false, none, block);
    }
}

.flow-tree {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &.first {
        padding: 20px;

        &.across {
            margin: 0 auto !important;
        }
    }

    .node-box {
        display: flex;

        .info-box {
            display: flex;

            .content-box {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .content-base {
                    position: relative;
                    border: 1px solid #f3f3f3;
                    border-radius: 6px;
                    background: #fff;
                    box-sizing: border-box;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

                    .sign-box {
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 5px 10px;
                        background: #e3e3e3;
                        font-size: 12px;
                        border-radius: 0 0 0 10px;
                        line-height: 1;
                        color: #888;
                    }

                    .edit-decrease {
                        .edit_btn(left, top, decrease);
                    }

                    .edit-increase {
                        .edit_btn(left, bottom, increase);
                    }

                    .unfold-btn {
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;

                        .edit_btn(left, bottom, false);

                        .icon {
                            font-size: 12px;

                            &:nth-child(1) {
                                position: absolute;
                                top: 1px;
                                right: 1px;
                                transform: rotate(225deg);
                            }

                            &:nth-child(2) {
                                position: absolute;
                                bottom: 1px;
                                left: 1px;
                                transform: rotate(45deg);
                            }
                        }

                        &.unfold {
                            .icon {
                                &:nth-child(1) {
                                    top: 2px;
                                    right: 2px;
                                    transform: rotate(45deg);
                                }

                                &:nth-child(2) {
                                    bottom: 2px;
                                    left: 2px;
                                    transform: rotate(225deg);
                                }
                            }
                        }
                    }

                    .content {
                        height: 100%;
                        overflow-y: auto;
                        overflow-x: hidden;

                        .box {
                            padding: 10px 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-height: 100%;
                        }
                    }
                }
            }
        }
    }

    // 竖向
    &.vertical {
        flex-direction: column;

        .node-box {
            justify-content: center;

            .info-box {
                flex-direction: column;

                .content-box {
                    padding: 0 10px;

                    &.top {
                        padding-top: 20px;

                        &.alone-top::after {
                            .vertical_col(top);
                        }
                    }

                    &.bottom {
                        padding-bottom: 20px;

                        &.alone-bottom::before {
                            .vertical_col(bottom);
                        }
                    }

                    .wire {
                        &.left {
                            &::after {
                                .vertical_row(top, right);
                            }

                            &.first::after,
                            &.last::after {
                                border-top-right-radius: @line-radius;
                            }
                        }

                        &.right {
                            &::before {
                                .vertical_row(top, left);
                            }

                            &.first::before,
                            &.last::before {
                                border-top-left-radius: @line-radius;
                            }
                        }
                    }

                    .wire-bottom {
                        &.left {
                            &::after {
                                .vertical_row(bottom, right);
                            }

                            &.first::after,
                            &.last::after {
                                border-bottom-right-radius: @line-radius;
                            }
                        }

                        &.right {
                            &::before {
                                .vertical_row(bottom, left);
                            }

                            &.first::before,
                            &.last::before {
                                border-bottom-left-radius: @line-radius;
                            }
                        }
                    }
                }
            }
        }
    }

    // 横向
    &.across {
        align-items: center;

        .node-box {
            flex-direction: column;

            .info-box {
                .content-box {
                    padding: 10px 0;

                    &.top {
                        padding-left: 20px;

                        &.alone-top::after {
                            .across_row(left);
                        }
                    }

                    &.bottom {
                        padding-right: 20px;

                        &.alone-bottom::before {
                            .across_row(right);
                        }
                    }

                    .wire {
                        &.left {
                            &::after {
                                .across_col(bottom, left);
                            }

                            &.first::after,
                            &.last::after {
                                border-bottom-left-radius: @line-radius;
                            }
                        }

                        &.right {
                            &::before {
                                .across_col(top, left);
                            }

                            &.first::before,
                            &.last::before {
                                border-top-left-radius: @line-radius;
                            }
                        }
                    }

                    .wire-bottom {
                        &.left {
                            &::after {
                                .across_col(bottom, right);
                            }

                            &.first::after,
                            &.last::after {
                                border-bottom-right-radius: @line-radius;
                            }
                        }

                        &.right {
                            &::before {
                                .across_col(top, right);
                            }

                            &.first::before,
                            &.last::before {
                                border-top-right-radius: @line-radius;
                            }
                        }
                    }

                    .content-base {
                        .edit-decrease {
                            .edit_btn(top, left, decrease);
                        }

                        .edit-increase {
                            .edit_btn(top, right, increase);
                        }

                        .unfold-btn {
                            .edit_btn(top, right, false);
                        }
                    }
                }
            }
        }
    }
}
</style>
