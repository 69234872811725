<template>
    <div>
        <CompModal ref="comp_modal" :title="detail.rootOrgName">
            <Table :columns="getPostTableColumn(true)" :data="postGroupList" height="300">
                <template #operate="{ row, index }">
                    <Button type="primary" size="small" style="margin-right: 5px" @click="onAddPrincipal(row)">修改</Button>
                    <Button type="info" size="small" @click="onDisplayHistoryPrincipal(index)">历任</Button>
                </template>
            </Table>
            <div class="form-box">
                <div class="block">
                    <div class="item-box" style="width:100%">
                        <div class="name">畅联组:</div>
                        <div class="value" v-if="detail.groupId">
                            <p style="color:#43aaea;border-bottom: 1px solid rgb(191,230,255)">{{ detail.groupName }}</p>
                            <Button type="info" size="small" style="margin-left:20px;" @click="route.jump('/groupindex')">进入</Button>
                            <Button type="error" size="small" style="margin-left:10px;" @click="onDeleteGroup">删除</Button>
                        </div>
                        <div class="value" v-else>
                            <Input v-model="detail.groupName" placeholder="输入畅联组名称" style="width:300px;margin-right:20px;" />
                            <!-- <ElemCheck v-model="detail.groupUserScope" :multiple="true" style="margin:10px 0;flex-shrink:0" :list="groupUserScopeList"></ElemCheck> -->
                        </div>
                    </div>
                </div>
                <div class="block">
                    <div class="item-box">
                        <div class="name">微信群:</div>
                        <div class="value">
                            <RadioGroup v-model="detail.weixinGroup">
                                <Radio label="1">已建设</Radio>
                                <Radio label="2">未建设</Radio>
                            </RadioGroup>
                        </div>
                    </div>
                </div>
                <div class="block">
                    <div class="item-box">
                        <div class="name">{{ levelName || "" }}名称:</div>
                        <div class="value">
                            <Input v-model="detail.rootOrgName" :placeholder="'请输入' + (levelName || '') + '名称'" />
                        </div>
                    </div>
                    <div class="item-box">
                        <div class="name">排序:</div>
                        <div class="value">
                            <Input v-model="detail.seq" type="number" placeholder="请输入排序" />
                        </div>
                    </div>
                    <div class="item-box">
                        <div class="name">包括门牌备注:</div>
                        <div class="value">
                            <Input v-model="detail.remark" :rows="4" type="textarea" placeholder="请输入备注" />
                        </div>
                    </div>
                </div>
            </div>

            <template #operate="{ close }">
                <div class="operate-btns">
                    <Button class="operate-btn" type="info" @click="close">取消</Button>
                    <Button class="operate-btn highlight" type="primary" @click="onSubmitUpdateInfo">保存</Button>
                </div>
            </template>
        </CompModal>

        <!-- 历任 -->
        <CompModal ref="history_modal" :title="detail.rootOrgName + ' - 历任'" maxWidth="1500px">
            <Table v-if="historyIndex != null" :columns="getPostTableColumn()" :data="postGroupList[historyIndex].child" height="500"></Table>
        </CompModal>

        <CompModal ref="add_modal" :title="detail.rootOrgName + ' - 添加负责人'" maxWidth="1500px">
            <div class="add-modal-box">
                <div class="table-box" style="max-height:400px">
                    <div class="row head">
                        <div class="col" style="width: 200px">岗位</div>
                        <div class="col" style="width: 200px">当前负责人</div>
                        <div class="col" style="width: 300px">负责时间段</div>
                        <div class="col" style="width: 200px">激活</div>
                        <div class="col" style="width: 200px">状态</div>
                    </div>
                    <div class="row" v-for="(item, idx) in principalList" :key="idx">
                        <div class="col" style="width: 200px">{{ item.postName }}</div>
                        <div class="col" style="width: 200px">{{ item.userName }}</div>
                        <div class="col" style="width: 300px">{{ $core.formatDate(new Date(item.startDate), "yyyy年MM月dd日") }} - {{ item.endDate ? $core.formatDate(new Date(item.endDate), "yyyy年MM月dd日") : "至今" }}</div>
                        <div class="col" style="width: 200px">{{ item.activeStatus == 1 ? "已激活" : "未激活" }}</div>
                        <div class="col" style="width: 200px">{{ item.workStatus == 1 ? "在职" : "离职" }}</div>
                    </div>
                    <div class="empty-box" v-if="!principalList || principalList.length == 0">暂无数据</div>
                </div>
                <div class="shuttle-box">
                    <div class="table-box">
                        <div class="head-box" v-if="searchFields && searchFields.length > 0">
                            <div v-for="item in searchFields" :key="item.fieldId" style="margin:5px 10px 5px 0">
                                <Select v-if="item.fieldType === 'radio' || item.fieldType === 'select' || item.fieldType === 'checkbox'" v-model.trim="searchData[item.fieldId]" :placeholder="'请选择' + item.fieldName" :multiple="item.fieldType === 'checkbox'" class="module" transfer>
                                    <Option v-for="opt in item.options" :key="opt.value" :value="opt.value">{{ opt.label }}</Option>
                                </Select>

                                <Select v-else-if="item.fieldType === 'switch'" v-model.trim="searchData[item.fieldId]" :placeholder="'请选择' + item.fieldName" class="module" transfer>
                                    <Option value="1">是</Option>
                                    <Option value="2">否</Option>
                                </Select>

                                <DatePicker v-else-if="item.fieldType === 'datePicker'" v-model.trim="searchData[item.fieldId]" class="module" type="date" placeholder="请选择日期" transfer />

                                <Input v-else v-model.trim="searchData[item.fieldId]" :placeholder="'请输入' + item.fieldName"></Input>
                            </div>
                            <Button class="btn" type="primary" @click="onSearchPersonnel">搜索</Button>
                            <Button class="btn" type="success" @click="onResetPersonnel">
                                重置
                            </Button>
                        </div>
                        <div class="head-box" v-else>
                            <p class="title">选择人员</p>
                        </div>
                        <div class="table">
                            <ElemTable ref="addPersonnelTable" isSelector :columns="addPersonnelTableColumn" :tableApi="addPersonnelTableApi" data="[]" method="post" :processData="onProcessTableData" height="500px"></ElemTable>
                        </div>
                    </div>
                    <div class="operate-box">
                        <Button type="primary" class="btn" @click="onPostPrincipal">添加</Button>
                        <Button type="error" class="btn" @click="onRemovePrincipal">删除</Button>
                    </div>
                    <div class="table-box">
                        <div class="head-box">
                            <p class="title">已选人员</p>
                        </div>
                        <div class="table border">
                            <Table ref="selectedPrincipalTable" size="small" :columns="getAddSelectedPersonnelTableColumn()" :data="selectedPrincipalList" height="500"></Table>
                        </div>
                    </div>
                </div>
            </div>

            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn" @click="evt.close">取消</div>
                    <div class="operate-btn highlight" @click="onSubmitUpdateInfo">确定</div>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
import ElemCheck from "../../customform/components/ElemCheck.vue"
import CompModal from "../../residentdatabase/components/CompModal.vue"
import UserInfo from "../entity/UserInfo"
import $core from "@/utils/core"
import Route from "../entity/Route"
import Upload from "../entity/Upload"
import ElemTable from "../components/ElemTable"

export default {
    components: {
        ElemCheck,
        CompModal,
        ElemTable,
    },

    data() {
        return {
            test: null,
            route: Route,
            addPersonnelTableColumn: [],
            addPersonnelTableApi: null,
            // 搜索字段
            searchFields: null,
            // 搜索数据
            searchData: {},

            // 历任
            historyIndex: null,
            // 详情数据
            detail: {},
            // 按岗位分组
            postGroupList: [],
            // 负责人列表
            principalList: [],
            // 已选人员
            selectedPrincipalList: [],
            // 层级名称
            levelName: "",
            // 搜索员工
            searchPersonnel: "",
            // 畅联组类型
            groupUserScopeList: [
                { name: "各级负责人（指当前及下一级的负责人，不包含户代表）", value: "1" },
                { name: "家庭户代表（责任区中划分的户代表）", value: "2" },
            ],
        }
    },

    methods: {
        async display(appId, item, posts, level) {
            // 初始化数据
            Object.assign(this.$data, this.$options.data())

            if (!this.appId !== appId) {
                this.getSearchFieldData(appId)
            }

            this.appId = appId
            this.item = item
            this.posts = posts
            this.level = level

            // 请求数据
            await this.getData()
            // 加载模版
            if (!this.template) await this.getTemplateList()
            // 层级名称
            this.levelName = this.template?.config?.[["firstRootOrgName", "secondRootOrgName"][level]] || ""
            // 刷新
            this.$forceUpdate()
            // 显示页面
            this.$refs.comp_modal.display()
        },

        getData() {
            return this.$get("/gateway/org/pc/grassrootsOrg/v2/selectRootOrgEditInfo", {
                rootOrgId: this.item.rootOrgId,
            }).then(res => {
                if (res.code == 200) {
                    const data = res.data

                    if (!this.groupId) {
                        // 没有创建群时，默认勾选群范围
                        data.groupUserScope = ["1", "2"]
                    }

                    this.detail = data

                    const admins = data.admins
                    const groupsList = []
                    const groups = {}

                    if (admins && admins.length > 0) {
                        admins.forEach(v => {
                            var list

                            if (groups[v.postId]) {
                                list = groups[v.postId]
                            } else {
                                list = groups[v.postId] = []
                            }

                            list.push(v)
                        })

                        Object.keys(groups).forEach(k => {
                            let l = groups[k]
                            groupsList.push({
                                ...l[0],
                                child: l,
                            })
                        })
                    }

                    // 补全所缺少的岗位
                    this.posts.forEach(v => {
                        if (!groups[v.postId]) {
                            groupsList.push({
                                postId: v.postId,
                                postName: v.postName,
                                child: [],
                            })
                        }
                    })

                    this.postGroupList = groupsList
                } else this.$Message.error(res.desc)
            })
        },

        /**
         * 获取模板列表
         */
        getTemplateList() {
            const orgType = this.item.orgType
            return this.$get("/gateway/org/pc/grassrootsOrg/selectTemplateList").then(res => {
                if (res.code === "200") {
                    for (let i = 0, ls = res.dataList; i < ls?.length; i++) {
                        let v = ls[i]
                        if (v.orgType === orgType) {
                            this.template = v
                            break
                        }
                    }
                }
            })
        },

        /**
         * 获取搜索字段数据
         */
        getSearchFieldData(appId) {
            this.$get("/gateway/api/fastDev/AppForm/getSearchField", {
                appId: appId,
            }).then(res => {
                if (res.code == 200) {
                    this.searchFields = res.dataList
                    this.searchData = {}
                } else {
                    this.$Message.error(res.desc.slice(0, 50))
                }
            })
        },

        setAppId(appId) {
            this.addPersonnelTableApi = `/gateway/api/fastDev/AppData/findPageData?appId=${appId}&desensitize=false`
        },

        /**
         * 搜索
         */
        onSearchPersonnel() {
            const res = []
            const sd = this.searchData

            for (let i = 0, keys = Object.keys(sd); i < keys.length; i++) {
                let k = keys[i]
                let value = sd[k]

                // 判空
                if (!value) continue

                // 处理日期格式数据
                if (value instanceof Date) {
                    value = this.$core.formatDate(value, "yyyy-MM-dd")
                }

                res.push({
                    queryFieldId: k,
                    queryFieldValue: value,
                    searchMethod: "Contains",
                })
            }

            this.$refs.addPersonnelTable.search(res)
        },

        /**
         * 重置搜索
         */
        onResetPersonnel() {
            this.searchData = {}
            this.$refs.addPersonnelTable.reset()
        },

        onProcessTableData(res) {
            const data = res.data

            const columns = []

            const columnNameList = data.columnNameList

            for (let i = 0; i < columnNameList.length; i++) {
                const c = columnNameList[i]

                columns.push({
                    title: c,
                    key: c,
                    align: "center",
                    render: (h, params) => {
                        const value = params.row[c]

                        // 判断值是否为图片链接
                        if (/http.*\.(jpg|jpeg|png)/.test(value)) {
                            var images

                            // 是否为数组
                            if (/^\[.*\]$/.test(value)) {
                                images = JSON.parse(value)
                            } else if (/\|/.test(value)) {
                                images = value.split("|")
                            } else if (/,/.test(value)) {
                                images = value.split(",")
                            } else {
                                images = [value]
                            }

                            const files = []

                            images?.forEach(item => {
                                if (!item) return
                                files.push(
                                    h("div", {
                                        style: {
                                            width: "50px",
                                            height: "50px",
                                            margin: "3px",
                                            background: `url(${item})`,
                                            "background-size": "cover",
                                            "background-position": "center",
                                            "border-radius": "3px",
                                            cursor: "pointer",
                                        },
                                        on: {
                                            click: () => {
                                                this.$refs.preview.display(item, images)
                                            },
                                        },
                                    })
                                )
                            })

                            return h(
                                "div",
                                {
                                    style: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "flex-wrap": "wrap",
                                        margin: "5px 0",
                                    },
                                },
                                files
                            )
                        }

                        return h("p", value || "-")
                    },
                })
            }

            // 最后添加操作列
            // columns.push(this.getOperate())

            const tableData = []
            const dataList = data.dataList

            for (let i = 0; i < dataList.length; i++) {
                const d = dataList[i]

                const obj = {
                    id: d.formDataId || d.id,
                }

                for (let ci = 0, cs = d.columnDataList; ci < cs.length; ci++) {
                    let text = cs[ci]

                    // 没有值，跳过
                    if (!text) {
                        continue
                    }

                    // 去除数组中括号
                    if (/^\[.*\]$/.test(text)) {
                        text = text.replace(/[\[\]"]/g, "")
                    }

                    obj[columnNameList[ci]] = text
                }

                tableData.push(obj)
            }

            this.addPersonnelTableColumn = columns
            data.dataList = tableData

            return data
        },

        /**
         * 添加负责人按钮
         */
        onAddPrincipal(item) {
            this.postConfig = item
            this.principalList = item.child
            this.selectedPrincipalList = item.child
            // 请求数据
            this.setAppId(this.appId)
            // 显示页面
            this.$refs.add_modal.display()
        },

        /**
         * 显示历任负责人按钮
         */
        onDisplayHistoryPrincipal(index) {
            this.historyIndex = index
            // 显示弹窗
            this.$refs.history_modal.display()
        },

        getPrincipalList() {
            this.$get("/gateway/org/pc/grassrootsOrg/v2/selectOrgAdminList", {
                rootOrgId: this.item.rootOrgId,
                postId: this.postConfig.postId,
                workStatus: 1,
            }).then(res => {
                if (res.code == 200) {
                    this.principalList = res.dataList
                    this.selectedPrincipalList = res.dataList
                }
            })
        },

        onPostPrincipal() {
            let selected = this.selectedPrincipalList
            let s = this.$refs.addPersonnelTable.getSelecteds()

            // 过滤已选
            selected.push(
                ...s
                    .filter(f => selected.findIndex(sf => sf.id === f.id) == -1)
                    .map(v => ({
                        id: v.id,
                        orgAppPostId: "",
                        postId: this.postConfig.postId,
                        postName: this.postConfig.postName,
                        rootOrgId: this.postConfig.rootOrg,
                        dataId: v.id,
                        startDate: new Date().getTime(),
                        userName: v["姓名"] || v[Object.keys(v).find(k => ~k.indexOf("姓名"))],
                        mobile: v["手机号码"] || v[Object.keys(v).find(k => ~k.indexOf("手机号码"))],
                        workStatus: "1",
                        activeStatus: "",
                        postFile: "",
                    }))
            )
        },

        async onSubmitUpdateInfo() {
            var data

            if (this.principalList.length > 0 || this.selectedPrincipalList.length > 0) {
                let list = this.getRemoveAdminsByPostId(this.postConfig.postId)

                this.selectedPrincipalList.forEach(v => {
                    list.push({
                        ...v,
                        postFile: v.postFile || "",
                    })
                })

                data = {
                    ...this.detail,
                    admins: list,
                }
            } else {
                data = this.detail

                if (data.groupName && !data.groupId) {
                    data.rootOrgLevel = this.level
                    data.creatorId = UserInfo.get("custGlobalId")
                    data.communityCode = UserInfo.getCommunityCode()
                }

                // 存在畅联组名称时，默认勾选已建设畅联组
                if (data.groupName && data.contactGroup !== "1") {
                    data.contactGroup = "1"
                }
            }

            var groupUserScope = ""

            if (data.groupUserScope) {
                groupUserScope = Array.isArray(data.groupUserScope) ? data.groupUserScope.filter(s => s && s.trim()).join(",") : data.groupUserScope
            }

            this.$post("/gateway/org/pc/grassrootsOrg/v2/updateOrgInfo", { ...data, rootOrgLevel: this.level + 2, groupUserScope, orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode }, { "Content-Type": "application/json" }).then(res => {
                if (res.code == 200) {
                    this.$Message.success("保存成功")
                    if (this.$refs.add_modal.isDisplay()) {
                        // 关闭添加人员页面
                        this.$refs.add_modal.close()
                        // 刷新数据
                        this.getData()
                    } else {
                        this.$refs.comp_modal.close()
                    }
                    // 触发刷新事件
                    this.$emit("on-change")
                } else this.$Message.error(res.desc)
            })
        },

        getRemoveAdminsByPostId(postId) {
            const admins = this.detail.admins || []
            const res = []

            for (let i = 0; i < admins.length; i++) {
                let v = admins[i]

                if (v.postId !== postId) {
                    res.push(v)
                }
            }

            return res
        },

        getPostTableColumn(operate) {
            let arr = [
                {
                    title: "岗位",
                    key: "postName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "负责人",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.userName || "-")
                    },
                },
                {
                    title: "手机号",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.mobile || "-")
                    },
                },
                {
                    title: "激活",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.activeStatus ? (params.row.activeStatus === "1" ? "已激活" : "未激活") : "-")
                    },
                },
                {
                    title: "状态",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.workStatus ? (params.row.workStatus === "1" ? "在职" : "离职") : "-")
                    },
                },
                {
                    title: "负责时间段",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        if (!params.row.startDate && !params.row.endDate) {
                            return h("div", {}, "-")
                        }

                        var start = params.row.startDate ? this.$core.formatDate(new Date(params.row.startDate), "yyyy年MM月dd日") : "至今"
                        var end = params.row.endDate ? this.$core.formatDate(new Date(params.row.endDate), "yyyy年MM月dd日") : "至今"

                        return h("p", `${start} - ${end}`)
                    },
                },
                {
                    title: "负责人证明",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.postFile ? "已上传" : "未上传")
                    },
                },
            ]

            if (operate) {
                arr.push({
                    title: "操作",
                    slot: "operate",
                    width: 150,
                    align: "center",
                    fixed: "right",
                })
            }

            return arr
        },

        getTitleName() {
            switch (this.item.orgType) {
                case "1":
                    return ["党支部", "党小组", "责任区"][this.level] + "名称"
                case "2":
                    return ["网格", "楼组", "责任区"][this.level] + "名称"
                case "3":
                    return ["经济社", "小队", "责任区"][this.level] + "名称"
                default:
                    return `第${this.level}层级名称`
            }
        },

        getAddSelectedPersonnelTableColumn() {
            const _this = this

            return [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "负责人",
                    key: "userName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "手机号",
                    key: "mobile",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "负责时间",
                    minWidth: 250,
                    align: "center",
                    render: (h, params) => {
                        if (!params.row.startDate && !params.row.endDate) {
                            return h("p", {}, "-")
                        }

                        var start = params.row.startDate ? $core.formatDate(new Date(params.row.startDate), "yyyy年MM月dd日") : "至今"
                        var end = params.row.endDate ? $core.formatDate(new Date(params.row.endDate), "yyyy年MM月dd日") : "至今"

                        return h("p", `${start} - ${end}`)
                    },
                },
                {
                    title: "负责人证明",
                    minWidth: 200,
                    align: "center",
                    render(h, params) {
                        if (params.row.postFile) {
                            return h("p", `已上传`)
                        }

                        return h(
                            "div",
                            {
                                style: {
                                    color: "#43aaea",
                                    cursor: "pointer",
                                },
                                on: {
                                    click() {
                                        let input = document.createElement("input")

                                        input.type = "file"
                                        input.multiple = false
                                        // Excel 文件
                                        input.onchange = async evt => {
                                            let postFile = await Upload.uploadFile(evt.target.files[0])
                                            // 写入属性
                                            _this.$set(_this.selectedPrincipalList[params.index], "postFile", postFile)
                                        }

                                        document.body.appendChild(input)
                                        // 点击事件
                                        input.click()
                                    },
                                },
                            },
                            "上传证明"
                        )
                    },
                },
            ]
        },

        onDeleteGroup() {
            this.$Modal.confirm({
                title: "提示",
                content: "确定删除该畅联组吗？",
                onOk: () => {
                    this.$post("/sendmsg/pc/chat/groupCreate/deleteGroup", {
                        groupId: this.detail.groupId,
                    }).then(res => {
                        if (res.code != 200) {
                            return this.$Message.error(res.desc || res.msg || "请求失败")
                        }

                        this.$Message.success("删除成功")
                        this.detail.groupId = null

                        // 触发刷新事件
                        this.$emit("on-change")
                    })
                },
            })
        },
    },
}
</script>

<style lang="less" scoped>
.form-box {
    padding: 20px;

    .block {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .item-box {
        width: 33.33%;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        @media screen and (max-width: 1200px) {
            width: 50%;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        .name {
            margin-bottom: 6px;
            color: #666;
        }

        .value {
            color: #000;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .input-box {
            margin-left: 20px;
            display: flex;
            align-items: center;

            .title {
                margin-right: 10px;
                flex-shrink: 0;
            }
        }

        .btn {
            margin-left: 20px;
        }
    }
}

.add-modal-box {
    padding: 30px;

    > .table-box {
        position: relative;
        width: 100%;
        background: #fff;
        border: 1px solid #f3f3f3;
        border-radius: 6px;
        overflow: auto;

        .row {
            min-width: 100%;
            display: flex;

            .col {
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #f1f1f1;
                border-bottom: 1px solid #f1f1f1;
                flex-grow: 1;
                min-width: 100px;

                &:last-child {
                    border-right: 0;
                }
            }

            &:last-child .col {
                border-bottom: 0;
            }

            &.head {
                position: sticky;
                top: 0;

                .col {
                    background: #f8f8f8;
                }
            }
        }

        .empty-box {
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #888;
        }
    }

    .shuttle-box {
        margin-top: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .table-box {
            &:first-child {
                width: calc(60% - 50px);
            }

            &:last-child {
                width: calc(40% - 50px);
            }

            .head-box {
                padding: 5px 0;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .input {
                    margin-right: 10px;
                }

                .btn {
                    margin: 5px 10px 5px 0;
                }

                .title {
                    text-align: center;
                    width: 100%;
                    font-size: 16px;
                    margin: 10px 0;
                    font-weight: 600;
                }
            }

            .table {
                width: 100%;

                &.border {
                    border: 1px solid #f3f3f3;
                }
            }
        }

        .operate-box {
            width: 80px;
            margin: 40px 10px 0 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn {
                margin: 5px 0;
            }
        }
    }
}
</style>
