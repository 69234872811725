<template>
    <div>
        <CompModal ref="comp_modal" title="人员管理" isFull @on-close="$emit('on-close', { tag: 'Member' })">
            <template #title>
                <p>人员管理</p>
                <Icon size="16" :type="desensitize ? 'md-eye-off' : 'md-eye'" style="margin-left:10px;cursor: pointer;" @click="desensitize = !desensitize" />
            </template>
            <div class="elem" v-show="!loading">
                <div class="head-box">
                    <div class="parents-box">
                        <div class="title">当前体系架构：</div>
                        <div class="item" v-for="(item, idx) in parentNames" :key="idx">{{ item }}</div>
                    </div>
                    <div class="explain-box">
                        <div class="title">说明：</div>
                        <block style="display:flex">
                            <div class="item-box blue">已激活 ( {{ activated }}人 )</div>
                            <div class="item-box red">未激活 ( {{ members.length - activated }}人 )</div>
                        </block>
                        <Button type="success" size="small" style="margin-left:10px" @click="$refs.partition_member.display(appId, nodeId, members)">添加人员</Button>
                        <Button type="info" size="small" style="margin-left:10px" @click="isEdit = !isEdit">{{ isEdit ? "完成" : "编辑人员" }}</Button>
                    </div>
                </div>
                <div class="layout-box">
                    <div class="item-box" v-for="(item, idx) in members" :key="idx">
                        <div class="total"></div>
                        <div class="room delete" v-if="isEdit" @click="onDeleteMember(item.dataId)">删除</div>
                        <div class="room not" v-else>未激活</div>
                        <div class="content">
                            <p class="name">{{ item.username }}</p>
                            <p class="phone" v-if="item.mobile">{{ item.mobile.split(",")[0] }}</p>
                        </div>
                    </div>
                    <div class="empty-box">
                        <Icon size="16" type="ios-alert" style="margin-right:10px" />
                        <span>人员列表为空，请点击添加按钮进行添加！</span>
                    </div>
                </div>
            </div>
            <div class="loading-box" v-show="loading">
                <Spin fix></Spin>
            </div>
        </CompModal>

        <PartitionMember ref="partition_member" @on-change="onChangePartitionMember"></PartitionMember>
    </div>
</template>

<script>
import Request from "../../jointly/utils/request"
import CompModal from "../../residentdatabase/components/CompModal.vue"
import PartitionMember from "./PartitionMember"

import UserInfo from "../entity/UserInfo"

export default {
    components: {
        PartitionMember,
        CompModal,
    },

    data() {
        return {
            // 成员列表
            members: [],
            // 已激活人数
            activated: 0,
            // 节点 ID
            nodeId: null,
            // APPID
            appId: null,
            // 编辑模式
            isEdit: false,
            // 父节点名称
            parentNames: [],
            // 显示加载中
            loading: true,
            // 脱敏
            desensitize: true,
        }
    },

    props: {
        edit: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        desensitize() {
            this.getSelectedMember()
        },
    },

    created() {
        this.levels = {}
    },

    mounted() {},

    methods: {
        display(nodeId, parentNames, appId) {
            this.nodeId = nodeId
            this.parentNames = parentNames
            this.appId = appId
            this.loading = true
            // 请求
            this.getSelectedMember().finally(() => {
                this.loading = false

                if (this.members?.length == 0) {
                    setTimeout(() => {
                        this.$refs.partition_member.display(appId, nodeId, [])
                    }, 500)
                } else {
                    // 打开弹窗
                    this.$refs.comp_modal.display()
                }
            })
        },

        async getSelectedMember() {
            this.members = await Request.post("/gateway/org/pc/grassrootsOrg/app/getHasDataUser", {
                rootOrgId: this.nodeId,
                desensitize: this.desensitize,
            })
        },

        onChangePartitionMember() {
            this.getSelectedMember().then(() => {
                if (this.members?.length > 0 && !this.$refs.comp_modal.isDisplay()) {
                    this.$refs.comp_modal.display()
                }
            })
        },

        /**
         * 删除单个人员
         */
        onDeleteMember(dataId) {
            this.$Modal.confirm({
                title: "删除确定",
                content: "您正在删除数据，是否继续？",
                onOk: () => {
                    this.$post("/gateway/org/pc/grassrootsOrg/app/deleteDataId", { dataId: dataId, rootOrgId: this.nodeId }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            const idx = this.members.findIndex(v => v.dataId === dataId)
                            // 删除单个数据
                            this.members.splice(idx, 1)
                        } else {
                            this.$Message.error({
                                content: res.desc || "删除失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
    },
}
</script>

<style lang="less" scoped>
.elem {
    margin: 0 20px;

    .head-box {
        position: sticky;
        padding: 10px 0;
        top: 0;
        margin-bottom: 10px;
        background: #fff;
        z-index: 30;
        box-shadow: 0 5px 10px rgba(255, 255, 255, 0.8);

        .parents-box {
            padding-bottom: 3px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .item {
                position: relative;
                padding-right: 20px;

                &::after {
                    content: ">";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:last-child::after {
                    display: none;
                }
            }
        }

        .explain-box {
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &:last-child {
                padding-bottom: 0;
            }

            .item-box {
                position: relative;
                margin: 5px 10px;
                line-height: 20px;

                &.blue,
                &.red {
                    padding-left: 50px;

                    &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 40px;
                        content: "";
                        border-radius: 4px;
                        background: #fff;
                        border: 1px solid #e3e3e3;
                    }
                }

                &.blue::after {
                    background: #5ab2e9;
                    border-color: #5ab2e9;
                }

                &.red::after {
                    background: #ff6262;
                    border-color: #ff6262;
                }
            }

            .intro {
                color: #999;
                font-size: 13px;
                margin: 5px 25px 5px 15px;
            }
        }
    }

    .layout-box {
        display: flex;
        flex-wrap: wrap;

        > .item-box {
            position: relative;
            margin: 0 12px 12px 0;
            padding: 10px;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
            display: flex;
            align-items: center;
            background: #fff;
            color: #333;

            .total {
                position: absolute;
                top: 0;
                left: 0;
                padding: 10px 0 0 10px;
                line-height: 1;
                font-size: 12px;
                color: green;
            }

            .content {
                margin-top: 30px;
                width: 130px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                color: #666;

                .name {
                    font-size: 14px;

                    &:last-child {
                        text-align: center;
                        line-height: 35px;
                    }
                }

                .phone {
                    font-size: 13px;
                }
            }

            .room {
                position: absolute;
                top: 5px;
                right: 5px;
                padding: 3px 10px;
                line-height: 1;
                font-size: 12px;
                background: #f8f8f8;
                border: 1px solid #ececec;
                border-radius: 4px;
                color: #4bccb4;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                box-sizing: border-box;
                white-space: nowrap;

                &.not {
                    color: #ff6262;
                }

                &.delete {
                    cursor: pointer;
                    background: #ff6262;
                    border-color: #ff6262;
                    color: #fff;
                }
            }

            &.selected {
                background: #5ab2e9;
                color: #fff;

                .total {
                    color: #f3f3f3;
                }
            }

            .remove-btn {
                position: absolute;
                top: -7px;
                right: -7px;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #ffb6b6;
                color: #fff;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                font-size: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .pointer {
            cursor: pointer;
        }

        .empty-box {
            margin: 100px 0;
            width: 100%;
            text-align: center;
            color: #999;
            font-size: 13px;
            display: none;
            align-items: center;
            justify-content: center;

            &:first-child {
                display: flex;
            }
        }
    }

    .empty {
        color: #999;
        padding: 10px 40px 20px 40px;
        display: flex;
        align-items: center;
    }
}

.loading-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>
